.App {
  /* text-align: center; */ 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;

}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.header {
  font-size: 110%;
  line-height: 2em;
  display: flex;
  border: thin transparent solid;
  border-radius: 3px;
  background-color: #9ac;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2.0em;
}
.header-preface {
  flex-basis: 5em;
  flex-shrink: 5em;
  text-align: center;
  border: thin transparent solid;
  border-top-right-radius: 3em;
  border-bottom-right-radius: 3em;
  background-color: #6e93d6;
  color: white;


}
.header-text {
  flex-grow: 1;
  padding-left: 1em;
  color: white;
}


/* new login form */
.word {
  display: inline-block;
  border: thin red solid;
}
.word > .title {
  border-bottom: thin red solid;
}
.word > .text {
  padding: 2em;
}



.error-text {
  text-align: center;
  color: #900;
  line-height: 3em;
}
.forgot-password{
  font-size: 80%; 
  text-align: center;
  margin-bottom: 1.5em;
}
.col1, .col2 { 
  border: thin black solid;
}

.sign-in-button {
  width: 100%;
}

.login-container2 {
  width: 100%;
}
.login-box2 {
  display: flex;

  margin: auto;
  margin-top: 4em;
  max-width: 1150px;
  border: thin #999 solid;
  background-color: white;
  filter: drop-shadow( 5px 5px 5px #666);
}


.login-left {
	flex-basis: 500px;
	/* width: 500px; */
}

.login-left.signup-state {
  flex-basis: 1100px;
  transition: flex-basis 1s;
}


.login-right {
  background: url("abstract.jpg");
	flex-grow: 1;
  flex-shrink: 0;
	background-color: #eee;
}

div.login-form {
	padding: 5em;
}

div.login-form.signup-state {
  display: flex;
}

.col1, .col2 { 
  flex-grow: 0.5;
  flex-shrink: 0.5;
}

.col1 {
  margin-right: 0.5em;
}
.col2 {
  margin-left: 0.5em;
}

@media only screen and (max-width: 750px ) {
  div.login-form.signup-state {
    display: block;
  }

  .col1, .col2 { margin: 1em; }

}

@media only screen and (max-width: 500px ) {
  div.login-form {
    padding: 1.0em;
    transition: padding 1s;
  }

}


h2 {
  margin-top: 1em;
  border-bottom: thin #999 solid;
}

.credit-button { 

  border-radius: 15px;
  border: thin white solid;
}



.button {
  border-radius: 3px;
}

.buttons-align-right {
  margin-top: 1.5em;
  text-align: right;
}
.buttons-align-right > input[type='button'] {
  margin-left: 1em;
}

.vertical.menu  > li {
  text-align: right;
}

.templatetext {
  padding: 1em;
  border: thin #282c34 dotted;
  background-color: white;
  font-family: 'Courier New', monospace;
}


.round {
  padding: 0.2em;
  background-color: #888;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.logLines.header {
  font-weight: bold;
  border-bottom: thin black solid;
}

.logLines > div:nth-child(1) { width: 4em; }
.logLines > div:nth-child(2) { width: 7em; }
.logLines > div:nth-child(3) { width: 14em; }
.logLines > div:nth-child(4) { width: 14em; }
.logLines > div:nth-child(5) { width: 7em; }
.logLines > div:nth-child(6) { width: 7em; }
.logLines > div:nth-child(7) { width: 7em; }
.logLines > div:nth-child(8) { width: 4em; }
.logLines > div:nth-child(9) { width: 7em; } 
.logLines > div:nth-child(10) { width: 7em;  }

.messageLine > div { width: 63em; padding-left: 7em; color: #666 }







.logLines, .messageLine {
  font-family: 'Courier New', monospace;
}

.messageLine {
  padding-bottom: 0.75em;
}

.logLines > div, .messageLine > div {
  display: inline-block;
}



.smol {
  font-size: 80%;
  padding-bottom: 1em;
}




.button-box {
  margin-top: auto;
  margin-bottom: auto;
  background-color: #f6f6f6;
}

.button-box > a {
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 1em;
}

.flex-grow-shrink {
  flex-grow: 1;
  flex-shrink: 0;
}



.flexbox {
  width: 100%;
  display: flex;
}
.logo {
  flex-grow: 1;
  flex-shrink: 0;
}
.buttons a  {
  color: white;
}
.layout-parent {
}

.layout-container {
  /*max-width: 50em; 
   margin: auto !important; */
}

.csv-error {
  color: #600;
}


.template.bordered {
  max-width: 50em;

} 

.bordered {
  /*border: thin #161f21 solid; */
  /* border-bottom: thin #999 solid; */
  border-radius: 4px;
  margin-top: 1em;
  margin-bottom: 1em;
 /*  background-color: #f6f6ff; */
}

.bordered.info {
  color: #666;
}


.line-item {
  padding: 1em;
  border-bottom: thin #999 dotted;
}



/* this might not be used anymore */
.csv-report {

  border: thin #161f21 solid;
  border-radius: 5px;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 2em;

}

.csv {
  font-family: 'Courier New', monospace;
}

.sms {
  width: 8em;
}

.channel {
  max-width: 50em;
}

.channel.wide {
  max-width: 100%;
}

.channel-title {
  font-size: 125%;
  font-weight: bold; 
  color:   #a43605;
  margin-bottom: 1.5em;
}

.menu-bar {
  line-height: 2em;
 /* background-color: #161f21; */
  background-color: #6e93d6;
  color: #fff;
  font-size: 100%;
  padding: .5em;
}


label {
  color: #666;
}
 
/*
label {
  padding-top: 0.5em;
  font-weight: bold;
}
*/

select {
  max-width: 300px;
}

input[type=password] {
  max-width: 200px;
}


h1 { 
  font-size: 150%;
  font-weight: bold;
/*  color: #a43605; */
}

h2 {
  font-size: 125%;
}

.validation-error {
  color: #f33 !important;
}


input[type=text] {
  max-width: 200px;
}


input.login-form-input {
  border-radius: 4px;
  font-size: 120%;
  max-width: 100%;
  padding: 1.25em;
}




.atooltip {
  color: #444;
  font-size: 70%;
}

.createAccountButton {
  line-height: 2em;
}


.login-title {
  padding-bottom: .5em;
}

.login-box {
  padding: 2em;
  border: 1px #999 solid;
  background-color: #fff;
  width: 320px;
}

.login-buttons {
  margin-top: 2em;
}

.align-right {
  text-align: right;
}

.spacer {
  height: 50px;
}

body {

  font: arial;
}


.loader {
  margin: auto;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
